import {useRuntimeConfig} from "#app";
import {computed, ref} from "vue";

// @ts-ignore
function gtag() {
    window.dataLayer.push(arguments);
}

export function GATracker() {


    const timeouts = ref<Array<Timeout>>([]);
    const cookiePolicy = computed(() => {
        return localStorage && typeof localStorage.getItem('cookies_policy') === 'string' ? JSON.parse(localStorage.getItem('cookies_policy')) : {
            analytics: false,
            marketing: false,
            checked: false
        };
    })

    const isProduction = computed(() => {
        return useRuntimeConfig().public.production;
    })

    const GATrackEvent = (data: { [k: string]: any }, timeout = 800) => {

        function trackEventIn() {
            if (cookiePolicy.value.analytics === true && isProduction.value) {
                window.dataLayer.push(data);
            } else if (!isProduction.value) {
                console.log('Tracking GTag Event');
                console.log('----------');
                console.log(data);
                console.log('----------');
            }
        }

        if (timeouts.value[data.event] !== undefined) {
            clearTimeout(timeouts.value[data.event]);
        }
        timeouts.value[data.event] = setTimeout(() => {
            trackEventIn();
        }, (timeout ? timeout : 50)); // Just in case GTM is not init yet

    }

    const initGTM = () => {
        const config = useRuntimeConfig().public.GOOGLE_TAG_MANAGER;

        window.dataLayer = window.dataLayer || [];

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
        /*

                window.dataLayer?.push(['consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                }]);
        */

        if (typeof config === "string" && config.length > 0 && cookiePolicy.value.analytics === true) {
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });

            setTimeout(function () {
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted'
                });
            }, 200);


        }
    }

    return {GATrackEvent, initGTM}
}
